<template>
  <div>    <b-overlay :show="loading">
    <b-card>

    <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-default"
            active-tab-class="font-weight-bold text-success"
            content-class="mb-3"
    >
    <b-tab  title="Tareas Programadas"> <template #title>
      <feather-icon icon="SettingsIcon" />
      <span>Tareas Programadas</span>
    </template>

      <b-form @submit.prevent>

        <b-row>
          <b-col md="4">
            <b-form-group
            >
              <h5 class="font-weight-bold">
                Tipo Filtro
              </h5>
              <v-select
                      item-text="title"
                      item-value="id"
                      v-model="filter.tipoFiltro"
                      @input="selectTipoFiltro"
                      label="title"
                      placeholder="Seleccione Tipo Filtro"

                      :options="optionTipoFiltro"
              />

              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Cursos Presensiales
                </h5>
                <div class="demo-inline-spacing curso">
                  <b-form-radio
                      v-model="filter.tipoCurso"
                      name="type_curso"
                      value="2"
                      class="custom-control-primary"
                      @change="changeTipoCurso"
                  >
                    Si
                  </b-form-radio>
                  <b-form-radio
                      v-model="filter.tipoCurso"
                      name="type_curso"
                      value="1"
                      class="custom-control-primary"
                      @change="changeTipoCurso"
                  >
                    No
                  </b-form-radio>
                </div>

            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <h5 class="font-weight-bold">
                Fecha de Ejecución
              </h5>
              <div class="input-group">
                <flat-pickr
                        v-model="filter.fechaEjecucion"
                        class="form-control"
                        placeholder="Seleccione Fecha"
                        :config="configTE"
                        reset-button
                />
                <div class="input-group-append">
                  <button class="btn-clear" type="button" title="Limpiar" data-clear>
                    <feather-icon
                            icon="XIcon"
                    />
                  </button>
                </div>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <h5 class="font-weight-bold">
                Hora de Ejecución
              </h5>
              <div class="input-group">
                <b-form-timepicker
                        locale="en"
                        v-model="filter.horaEjecucion"
                        placeholder="Seleccione Hora"
                ></b-form-timepicker>
              </div>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group

            >
              <h5 class="font-weight-bold">
                Fichas
              </h5>

              <autocomplete-infinity
                      url="filters/listado-fichas"
                      placeholder="Seleccione Ficha"
                      @item-selected="selectFicha"
                      label="num_ficha"
                      id="ficha"
                      itemsPerPage="15"
                      :disabled="enableFicha"
                      :params="getFiltersFicha()"
                      :selected-value="filter.ficha"
                      :multiple="true"

              ></autocomplete-infinity>

            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group

            >
              <h5 class="font-weight-bold">
                Año
              </h5>
              <v-select
                      v-model="filter.anno"
                      label="year"
                      placeholder="Seleccione Año"
                      :options="optionAnnos"
                      :disabled="enableAnno"
                      multiple
              >
                <template slot="no-options" v-if="loadingYears">
                  <li
                          v-show="loadingYears"
                          ref="load"
                          class="loader"
                  >
                    <div class="d-flex align-items-center">
                      <strong>Cargando...</strong>
                      <b-spinner
                              variant="primary"
                              class="ml-auto"
                      />
                    </div>
                  </li>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <h5 class="font-weight-bold">
                Fecha Inicio
              </h5>
              <div class="input-group">
                <flat-pickr
                        v-model="filter.fechaInicio"
                        class="form-control"
                        placeholder="Click para seleccionar..."
                        :config="config"
                        :disabled="enableFechas"
                        reset-button
                />
                <div class="input-group-append">
                  <button class="btn-clear" type="button" title="Limpiar" data-clear>
                    <feather-icon
                            icon="XIcon"
                    />
                  </button>
                </div>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <h5 class="font-weight-bold">
                Fecha Fin
              </h5>
              <div class="input-group">
                <flat-pickr
                        v-model="filter.fechaFinal"
                        class="form-control"
                        placeholder="Click para seleccionar..."
                        :config="config"
                        :disabled="enableFechas"
                        reset-button
                />
                <div class="input-group-append">
                  <button class="btn-clear" type="button" title="Limpiar" data-clear>
                    <feather-icon
                            icon="XIcon"
                    />
                  </button>
                </div>
              </div>
            </b-form-group>
          </b-col>


          <b-col md="12">
            <div class="text-right mt-2">
              <b-button class="mr-1"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                        @click="limpiarComponentes">
                <feather-icon
                        icon="RefreshCcwIcon"
                        class="mr-50"
                />
                <span class="align-middle">Limpiar</span>
              </b-button>
              <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      type="submit"
                      @click="guardar">

                <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                />
                <span class="align-middle">Guardar</span>
              </b-button>

            </div>
          </b-col>
        </b-row>
      </b-form>

    </b-tab>
      <b-tab  title="Cierre de Curso"> <template #title>
        <feather-icon icon="ServerIcon" />
        <span>Cierre de Curso</span>
      </template>
        <cierre-curso></cierre-curso>
      </b-tab>
    <div class="d-none">{{showMensaje}}</div>
    </b-tabs>
    </b-card>
  </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadio, BFormDatepicker, BFormTimepicker,
    BSpinner,BTab,BTabs

} from 'bootstrap-vue'
import moment from "moment";
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import CierreCurso from "@/views/gestionFiltro/CierreCurso";
export default {
  name: "GestionFiltro",
  components: {
    AppCollapse,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadio,
    flatPickr,
    AppCollapseItem,
    BFormDatepicker,
    vSelect,
    BFormTimepicker,
    BSpinner,
    BTab,
    BTabs,
    'cierre-curso': CierreCurso,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filter: {
        ficha: [],
        anno: [],
        fechaInicio: null,
        fechaFinal: null,
        fechaEjecucion: null,
        horaEjecucion: null,
        tipoCurso: 1,
        tipoFiltro: null,

      },
      optionTipoFiltro:[
          {id: 1, title:'Fichas', type:'fichas'},
          {id: 2, title:'Rango de Fechas', type:'fechas'},
          {id: 3, title:'Años', type:'years'},
      ],
      enableFicha:true,
      enableFechas:true,
      enableAnno:true,
    }
  },
  mounted() {
    this.getYears()
  },
  computed: {
    config() {
      return {
        locale: require(`flatpickr/dist/l10n/es.js`).default['es'],
        dateFormat: "d/m/Y",
        wrap: true,
      }
    },
    configTE() {
      return {
        locale: require(`flatpickr/dist/l10n/es.js`).default['es'],
        dateFormat: "d/m/Y",
        wrap: true,
        minDate: "today",
      }
    },
    loading() {
      return this.$store.state.gestionFiltros.loading;
    },
    loadingYears() {
      return this.$store.state.gestionFiltros.loadingYears;
    },
    optionAnnos() {
      return this.$store.state.gestionFiltros.years;
    },
    mensaje() {
      return this.$store.state.gestionFiltros.mensaje;
    },
    showMensaje() {
      if (this.mensaje != ''){
        this.showToast('success',this.mensaje)
        this.$store.commit('gestionFiltros/setMensaje', '')
      }

    },
  },
  methods:{
    getYears() {
      let filterData = {
        'type_curso': this.filter.tipoCurso,
      }
      this.$store.dispatch('gestionFiltros/getYears',filterData)
    },
    selectFicha(ficha) {
      this.filter.ficha = ficha != null ? ficha : null;
    },
    selectAnno(anno) {
      this.filter.anno = anno != null ? anno.year : null;
    },
    getFiltersFicha() {
      return {
        "type_curso" :this.filter.tipoCurso,
      }
    },
    changeTipoCurso(){
      this.filter.ficha = null
    },
    limpiarComponentes() {
      this.filter.ficha = null
      this.filter.anno = null
      this.filter.fechaInicio= null
      this.filter.fechaFinal= null
      this.filter.fechaEjecucion= null
      this.filter.horaEjecucion= null
      this.filter.tipoFiltro= null

      this.enableFicha=true
      this.enableFechas=true
      this.enableAnno=true

    },
    guardar() {

      if (this.filter.tipoFiltro == null)
      {
        this.showToast('warning','Debe selecccionar un tipo de filtro')
        return
      }
      else
      {
        if (this.filter.tipoFiltro.type == 'fichas'){
          if (this.filter.ficha == null || this.filter.ficha.length == 0) {
            this.showToast('warning', 'Debe selecccionar al menos una ficha')
            return
          }
        }
        if (this.filter.tipoFiltro.type == 'fechas'){
          if (this.filter.fechaInicio == null) {
            this.showToast('warning', 'Debe selecccionar la fecha de inicio')
            return
          }
          if (this.filter.fechaFinal == null) {
            this.showToast('warning', 'Debe selecccionar la fecha final')
            return
          }
        }
        if (this.filter.tipoFiltro.type == 'years'){
          if (this.filter.anno == null || this.filter.anno.length == 0) {
            this.showToast('warning', 'Debe selecccionar al menos un año')
            return
          }
        }
      }

      let data = "";
      switch (this.filter.tipoFiltro.type){
        case 'fichas':
          let listFichas=[];
          this.filter.ficha.forEach((item) => {
            listFichas.push(item.num_ficha);
          })
           data = {
            fichas: listFichas,
          };
          break;
        case 'fechas':
           data = {
            fecha_inicio : this.filter.fechaInicio,
            fecha_final : this.filter.fechaFinal,
          };
          break;
        case 'years':
          let listAnnos=[];
          this.filter.anno.forEach((item) => {
            listAnnos.push(item.year);
          })
           data = {
            years: listAnnos,
          };
          break;
      }

      const result =  {
        tipo: this.filter.tipoFiltro.type,
        origen: this.filter.tipoCurso == 1 ? 'reports_data' : 'reports_data_complement',
        fecha_ejecucion: this.filter.fechaEjecucion ? this.filter.fechaEjecucion: '',
        hora_ejecucion: this.filter.horaEjecucion ? this.filter.horaEjecucion: '',
        data: data
      };

      console.log(JSON.stringify(result, null, 2), 'data a enviar')
      this.$store.dispatch('gestionFiltros/sendFilters',result)
    },
    selectTipoFiltro(filtro) {
      switch (filtro.id) {
        case 1:
          this.enableFicha=false
          this.enableFechas=true
          this.enableAnno=true
          this.filter.fechaInicio = null
          this.filter.fechaFinal = null
          this.filter.anno = null
          break;
        case 2:
          this.enableFicha=true
          this.enableFechas=false
          this.enableAnno=true
          this.filter.ficha = null
          this.filter.anno = null
          break;
        case 3:
          this.enableFicha=true
          this.enableFechas=true
          this.enableAnno=false
          this.filter.ficha = null
          this.filter.fechaInicio = null
          this.filter.fechaFinal = null

          break;

      }
    },
    showToast(variant, mensaje) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text: mensaje,
          variant,
        },
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.demo-inline-spacing.curso .custom-radio {
  margin-top: 0.5rem !important;
}

.btn-clear {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0px 3px 3px 0px!important;
  color: #6e6b7b;
}
</style>
