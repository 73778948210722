<template>
    <div>
        <b-overlay :show="loading">
            <b-card>
                <b-form @submit.prevent>

                    <b-row>

                        <!--<b-col md="12">
                            <b-form-group>
                                <h5 class="font-weight-bold">
                                    Ejecutar Comando Por
                                </h5>
                                <div class="demo-inline-spacing curso">
                                    <b-form-radio
                                            v-model="filter.tipoEjecucion"
                                            name="type_curso"
                                            value="1"
                                            class="custom-control-primary"
                                    >
                                        Ficha
                                    </b-form-radio>
                                    <b-form-radio
                                            v-model="filter.tipoEjecucion"
                                            name="type_curso"
                                            value="2"
                                            class="custom-control-primary"
                                    >
                                        Fecha
                                    </b-form-radio>
                                </div>

                            </b-form-group>
                        </b-col>-->

                        <b-col md="6">
                            <b-form-group

                            >
                                <h5 class="font-weight-bold">
                                    Fichas
                                </h5>

                                <autocomplete-infinity
                                        url="filters/listado-fichas"
                                        placeholder="Seleccione Ficha"
                                        @item-selected="selectFicha"
                                        label="num_ficha"
                                        id="ficha"
                                        itemsPerPage="15"
                                        :params="getFiltersFicha()"
                                        :selected-value="filter.ficha"
                                        :multiple="true"

                                ></autocomplete-infinity>

                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group>
                                <h5 class="font-weight-bold">
                                    Fecha de Ejecución
                                </h5>
                                <div class="input-group">
                                    <flat-pickr
                                            v-model="filter.fechaEjecucion"
                                            class="form-control"
                                            placeholder="Seleccione Fecha"
                                            :config="config"
                                            reset-button
                                    />
                                    <div class="input-group-append">
                                        <button class="btn-clear" type="button" title="Limpiar" data-clear>
                                            <feather-icon
                                                    icon="XIcon"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>


                        <b-col md="12">
                            <div class="text-right mt-2">
                                <b-button class="mr-1"
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-primary"
                                          type="submit"
                                          @click="limpiarComponentes">
                                    <feather-icon
                                            icon="RefreshCcwIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Limpiar</span>
                                </b-button>
                                <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        type="submit"
                                        @click="guardar">

                                    <feather-icon
                                            icon="SaveIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Guardar</span>
                                </b-button>

                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-overlay>
        <div class="d-none">{{showMensaje}}</div>
    </div>
</template>

<script>
    import {
        BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadio, BFormDatepicker, BFormTimepicker,
        BSpinner
    } from 'bootstrap-vue'
    import moment from "moment";
    import Ripple from 'vue-ripple-directive'
    import flatPickr from 'vue-flatpickr-component'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import vSelect from 'vue-select'
    import ToastificationContent from "@core/components/toastification/ToastificationContent";
    export default {
        name: "CierreCurso",
        components: {
            AppCollapse,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BForm,
            BButton,
            BFormRadio,
            flatPickr,
            AppCollapseItem,
            BFormDatepicker,
            vSelect,
            BFormTimepicker,
            BSpinner,
        },
        directives: {
            Ripple,
        },
        data() {
            return {
                filter: {
                    fichas: [],
                    fechaEjecucion: null,
                },

            }
        },
        mounted() {
        },
        computed: {
            config() {
                return {
                    locale: require(`flatpickr/dist/l10n/es.js`).default['es'],
                    dateFormat: "d/m/Y",
                    wrap: true,
                }
            },
            loading() {
                return this.$store.state.gestionFiltros.loading;
            },
            mensaje() {
                return this.$store.state.gestionFiltros.mensaje;
            },
            showMensaje() {
                if (this.mensaje != ''){
                    this.showToast('success',this.mensaje)
                    this.$store.commit('gestionFiltros/setMensaje', '')
                }

            },
        },
        methods:{
            selectFicha(ficha) {
                this.filter.ficha = ficha != null ? ficha : null;
            },
            getFiltersFicha() {
                return {
                    "type_curso" :1,
                }
            },
            limpiarComponentes() {
                this.filter.ficha = null
                this.filter.fechaEjecucion= null

            },
            guardar() {

            if ((this.filter.ficha == null || this.filter.ficha.length == 0) && this.filter.fechaEjecucion == null) {
                this.showToast('warning', 'Debe selecccionar al menos una ficha ó una fecha')
                return
            }
                let listFichas=[];
                this.filter.ficha.forEach((item) => {
                    listFichas.push(item.num_ficha);
                })

                let data = {
                    fichas: listFichas,
                };


                const result =  {
                    tipo: 'cierre_curso',
                    fecha_ejecucion: this.filter.fechaEjecucion ? this.filter.fechaEjecucion: '',
                    data: data,
                    origen: ''
                };

                console.log(JSON.stringify(result, null, 2), 'data a enviar')
                this.$store.dispatch('gestionFiltros/sendFilters',result)
            },
            showToast(variant, mensaje) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notificación',
                        icon: 'BellIcon',
                        text: mensaje,
                        variant,
                    },
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .demo-inline-spacing.curso .custom-radio {
        margin-top: 0.5rem !important;
    }

    .btn-clear {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d8d6de;
        border-radius: 0px 3px 3px 0px!important;
        color: #6e6b7b;
    }
</style>
